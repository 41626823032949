import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid'
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import Cards from "../Cards";
import PreviewCompatibleImage from "../PreviewCompatibleImage";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import styles from "./styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { StaticQuery, graphql, Link } from "gatsby";

import { useTheme } from '@material-ui/core/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BookingModal = ({ classes, variant, color = "primary", data, text }) => {


  const { booking } = data.allMarkdownRemark.edges[0].node.frontmatter;

  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState("");
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSelected("")
    setOpen(false);
  };

  return (
    <Grid container
      direction='column'
      justifyContent="center"
      alignContent='center'
      alignItems="center"
      spacing={2}

      className={classes.root}

    >
      {selected ? <Button
        size="small"
        variant="outlined"
        color="primary"

        onClick={handleClose}
      >
        Advisors
      </Button> :


        <Typography variant="h3" >
          Schedule a meeting with an advisor
        </Typography>}


      <Grid container
        className={classes.container}
        justifyContent="center"
        alignContent='center'
        alignItems="center"
        spacing={2}

      >



        {!selected && booking.blurbs.map((card, i) =>
          <Grid
            key={card.title}
            container
            justifyContent='center'
            alignItems='center'
            item
            xs={12}
            sm={6}
          >
            <Card className={classes.card}  >

              <CardActionArea
                focusRipple
                onClick={() => setSelected(card.link)}
                className={classes.link2}
              // component={Link}
              // to={card.link}
              >
                <CardMedia
                  className={classes.media}
                  classes={{ img: classes.image }}
                  children={
                    <>{card?.image && <PreviewCompatibleImage
                      // className={classes.image}
                      imageInfo={card}
                    />}</>
                  }
                />
                <CardContent
                  className={classes.overlaySecondary}>

                  <Typography gutterBottom variant="h3" component="h3"
                    className={classes.title}>
                    {card.title}
                  </Typography>
                  <Typography gutterBottom variant="h3" component="h3"
                    className={classes.text}
                  >
                    {card.name}
                  </Typography>

                  <Typography gutterBottom variant="h5" component="h6"
                    className={classes.text}>
                    {card.text}
                  </Typography>
                  <Typography gutterBottom variant="body1" component="p"
                    className={classes.text}>
                    {card.lang}
                  </Typography>



                </CardContent>
              </CardActionArea>

            </Card>
          </Grid>)}
        {selected &&
          <iframe
            title='calendar'
            src={selected}

            width="100%"
            height={900}
            frameBorder="0"></iframe>}
      </Grid>

    </Grid>
  );
}
BookingModal.propTypes = {
  classes: PropTypes.shape({}),
};

const AdvisorsQuery = ({ location, classes, variant, text }) => (
  <StaticQuery
    query={graphql`
      query advisorsQuery {
        allMarkdownRemark(
          filter: { frontmatter: { key: { eq: "booking" } } }
        ) {
          edges {
            node {
              frontmatter {
                booking {
                  heading
                  description
                  blurbs {
                    title
                    name
                    link
                    text
                    lang
                    image {
                      childImageSharp {
                        fluid(maxWidth: 500, quality: 100) {
                          ...GatsbyImageSharpFluid
                        }
                    }
                  }
                }
              }
            }
          }
        }
        }
      }
    `}
    render={(data) => (
      <BookingModal data={data} variant={variant} location={location} classes={classes} text={text} />
    )}
  />
);

export default withStyles(styles)(AdvisorsQuery);
