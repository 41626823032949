import React from 'react'
import { kebabCase } from 'lodash'
import Container from "@material-ui/core/Container";
import Cards from "../../components/Cards";
import SEO from "../../components/SEO";
import HeaderSection from "../../components/HeaderSection"; import { Link, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import { makeStyles } from "@material-ui/styles";
import BookingAdvisors from '../../components/BookingAdvisors';
const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  root: {
    flexGrow: 1,
  },
}));
const AdvisorsPage = ({
  location
}) => {
  const classes = useStyles();

  return (
    <Layout location={location}>
      <SEO
        title={"Advisors"}
        location={location}
      />
      <Container maxWidth="lg" className={classes.root}>
        <BookingAdvisors />
      </Container>

    </Layout>
  )
}

export default AdvisorsPage


