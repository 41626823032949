import { alpha } from "@material-ui/core";

const styles = (theme) => ({
  appBar: {
    position: 'relative',

  },
  card: {
    position: 'relative',
    margin: theme.spacing(1),
    flex: 1,
    height: 400,
    maxWidth: 400,

    [theme.breakpoints.down("sm")]: {
      maxWidth: 300,
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: 450,
      height: 300,
    },
  },
  media: {
    height: "100%",
  },
  image: {

    objectFit: "fill",
    height: "100%",
    width: "100%",
    opacity: 0.5,
  },

  link2: {
    height: "100%",
    width: "100%",
    '&:hover': {
      '& $overlaySecondary': {
        background: `linear-gradient(180deg , ${alpha(theme.palette.common.white, 0.8)},${alpha(theme.palette.common.white, 0.5)
          } 20%, ${alpha(theme.palette.common.white, 0.7)} 95%)`,
        color: theme.palette.text.primary,
      },

    },
  },
  title: {
    marginLeft: theme.spacing(2),
    color: theme.palette.common.white,
    flex: 1,
  },
  text: {
    color: theme.palette.common.white,

  },
  link: {
    color: theme.palette.common.white,
    cursor: "pointer",
    '&:hover': {
      color: theme.palette.secondary.light,
    },

  },
  overlaySecondary: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: '100%',
    width: "100%",
    color: theme.palette.common.white,
    // background: `radial-gradient(circle at center , ${alpha(theme.palette.common.black, 0.2)},${alpha(theme.palette.common.black, 0.3)
    //   } 90%, ${alpha(theme.palette.common.black, 0.9)} 100%)`,
    background: `linear-gradient(180deg , ${alpha(theme.palette.grey[900], 0.6)},${alpha(theme.palette.grey[300], 0.1)
      } 45%, ${alpha(theme.palette.grey[900], 0.7)}65%)`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",

  },
  overflow: {

    [theme.breakpoints.up("sm")]: {
      overflow: "hidden",
    }
  },

  container: {
    flex: 1,
    // minHeight: 800,
    padding: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),

      // minHeight: 500,
    },

  },
  root: {
    marginTop: theme.spacing(4),
  },


});
export default styles;
